<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Cadastro de usuário do sistema</h2>
        <hr />
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="registerUsuario">
            <div class="row">
              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Nome de usuário"
                  rules="required"
                >
                  <v-text-field
                    type="text"
                    v-model="nomeUsuario"
                    label="Nome de usuário (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <v-text-field
                    :error-messages="errors"
                    v-model="email"
                    label="Email (Obrigatório)"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Senha"
                  rules="required"
                >
                  <v-text-field
                    type="password"
                    v-model="senha"
                    label="Senha (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Confirmar senha "
                  rules="required"
                >
                  <v-text-field
                    type="password"
                    v-model="senhaConfirm"
                    label="Confirmar senha (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4 mt-4">
                <h6 style="color: grey">Setor</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Setor"
                  rules="required"
                >
                  <b-form-select
                    v-model="setor"
                    :error-messages="errors"
                    :options="options"
                  ></b-form-select>
                </validation-provider>
              </div>

              <div class="col-md-4 mt-4">
                <h6 style="color: grey">Tiṕo de usuário</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Tipo de usuário"
                  rules="required"
                >
                  <b-form-select
                    v-model="tipoSelecionado"
                    :error-messages="errors"
                    :options="options2"
                  ></b-form-select>
                </validation-provider>
              </div>

              <div class="col-md-4 mt-4">
                <h6 style="color: grey">Sexo</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Sexo"
                  rules="required"
                >
                  <b-form-select
                    v-model="sexo"
                    :error-messages="errors"
                    :options="options3"
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>

            <div class="row mt-2 " v-if="tipoSelecionado == 2">
              <div class="col-md-4 mt-4">
                <validation-provider
                  v-slot="{ errors }"
                  name="CRM"
                  rules="required"
                >
                  <v-text-field
                    type="text"
                    v-model="crm"
                    label="CRM (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-4 mt-1">
                <h6 style="color: grey">UF CRM</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="UF CRM"
                  rules="required"
                >
                  <b-form-select
                    v-model="ufcrm"
                    :error-messages="errors"
                    :options="ufs"
                  ></b-form-select>
                </validation-provider>
              </div>

              <div class="col-md-4 mt-1">
                <h6 style="color: grey">Especialidade</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Especialidade"
                  rules="required"
                >
                  <b-form-select
                    v-model="especialidadeSelect"
                    :error-messages="errors"
                    :options="especialidadesOptions"
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>

            <div class="row mt-2 " v-if="tipoSelecionado == 3">
              <div class="col-md-6 mt-4">
                <validation-provider
                  v-slot="{ errors }"
                  name="COREN"
                  rules="required"
                >
                  <v-text-field
                    type="text"
                    v-model="coren"
                    label="COREN (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-6 mt-1">
                <h6 style="color: grey">UF COREN</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="UF COREN"
                  rules="required"
                >
                  <b-form-select
                    v-model="ufcoren"
                    :error-messages="errors"
                    :options="ufs"
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>

            <div class="row mt-2" v-if="tipoSelecionado == 4">
              <div class="col-md-12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Matrícula"
                  rules="required"
                >
                  <v-text-field
                    type="text"
                    v-model="matricula"
                    label="Matrícula (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row mt-2" v-if="tipoSelecionado != 0">
              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Nome Completo"
                  rules="required"
                >
                  <v-text-field
                    type="text"
                    v-model="nome"
                    label="Nome Completo (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Data de nascimento"
                  rules="required"
                >
                  <v-text-field
                    type="date"
                    v-model="dataNascimento"
                    label="Data de Nascimento (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row mt-2" v-if="tipoSelecionado != 0">
              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="CPF"
                  rules="required"
                >
                  <v-text-field
                    v-mask="'###.###.###-##'"
                    type="text"
                    v-model="cpf"
                    label="CPF (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="RG"
                  rules="required"
                >
                  <v-text-field
                    v-mask="'##.###.###-#'"
                    type="text"
                    v-model="rg"
                    label="RG (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <v-btn
              :disabled="invalid"
              @click="registerUsuario"
              class="mr-4 mt-6 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Cadastrar</span>
            </v-btn>

            <router-link to="/painelContasUsuarios" v-slot="{ href, navigate }">
              <a :href="href" class="menu-link" @click="navigate">
                <v-btn class=" textStyle mt-6" tile color="#1dd1a1">
                  <span style="color: white">Voltar</span>
                </v-btn>
              </a>
            </router-link>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
import { required } from "vee-validate/dist/rules";
import { mask } from "vue-the-mask";
import axios from "axios";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido"
});

export default {
  name: "cadastrarContaUsuario",
  directives: { mask },
  components: {
    ValidationProvider,
    ValidationObserver
  },

  async created() {
    try {
      this.ufs = [{ value: null, text: "Selecione um UF" }];
      const { data } = await axios.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
      );

      data.map(
        uf => (this.ufs = [...this.ufs, { value: uf.sigla, text: uf.sigla }])
      );
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      crm: "",
      ufcrm: null,
      especialidadeSelect: null,
      ufs: null,
      coren: "",
      ufcoren: null,
      matricula: "",
      nome: "",
      dataNascimento: "",
      rg: "",
      cpf: "",
      sexo: null,
      tipoSelecionado: null,
      invalid: false,
      nomeUsuario: "",
      email: "",
      senha: "",
      senhaConfirm: "",
      setor: null,
      options: [
        { value: null, text: "Selecione um setor (Obrigatório)" },
        { value: 1, text: "Recepção" },
        { value: 2, text: "Triagem" },
        { value: 3, text: "Consulta" }
      ],
      options2: [
        { value: null, text: "Selecione um tipo de usuário (Obrigatório)" },
        { value: 0, text: "Adminstrador" },
        { value: 1, text: "Recepcionista" },
        { value: 2, text: "Médico" },
        { value: 3, text: "Enfermeiro" },
        { value: 4, text: "Aluno" }
      ],
      options3: [
        { value: null, text: "Selecione um sexo (Obrigatório)" },
        { value: 1, text: "Masculino" },
        { value: 2, text: "Feminino" }
      ],
      especialidadesOptions: [
        { value: null, text: "Selecione uma especialidade (Obrigatório)" },
        { value: 1, text: "Medicina de família e comunidade" },
        { value: 2, text: "Neurologia" },
        { value: 3, text: "Cardiologia" },
        { value: 4, text: "Endocrinologia" },
        { value: 5, text: "Pediatria" },
        { value: 6, text: "Ginecologia" },
        { value: 7, text: "Obstetrícia" },
        { value: 8, text: "Reumatologia" },
        { value: 9, text: "Dermatologia" }
      ]
    };
  },

  methods: {
    registerUsuario: async function() {
      if (this.tipoSelecionado == 0) {
        try {
          this.$refs.observer.validate();

          var data = {
            usuario: {
              email: this.email,
              password: this.senha,
              password_confirmation: this.senhaConfirm,
              username: this.nomeUsuario,
              setor_id: this.setor
            }
          };

          const response = await ambulatorio.registerUser(data);

          console.log(response);

          Swal.fire({
            title: "",
            text: "Cadastro de adminstrador realizado com sucesso",
            icon: "success",
            confirmButtonColor: "green",
            heightAuto: false
          });

          setTimeout(() => {
            this.$router.push({ name: "painelUsuariosContas" });
          }, 1000);
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: "",
            text:
              "Erro ao realizar cadastro de adminstrador, verifique as informações",
            icon: "error",
            confirmButtonColor: "red",
            heightAuto: false
          });
        }
      } else if (this.tipoSelecionado == 1) {
        try {
          this.$refs.observer.validate();

          var data2 = {
            recepcionista: {
              pessoa_attributes: {
                nome: this.nome,
                data_nascimento: this.dataNascimento,
                rg: this.rg,
                cpf: this.cpf,
                sexo_id: this.sexo
              },
              usuario_attributes: {
                email: this.email,
                password: this.senha,
                username: this.nomeUsuario,
                setor_id: this.setor
              }
            }
          };

          const response = await ambulatorio.registerUserRecepcionista(data2);

          console.log(response);

          Swal.fire({
            title: "",
            text: "Cadastro de recepcionista realizado com sucesso",
            icon: "success",
            confirmButtonColor: "green",
            heightAuto: false
          });

          setTimeout(() => {
            this.$router.push({ name: "painelUsuariosContas" });
          }, 1000);
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: "",
            text:
              "Erro ao realizar cadastro de recepcionista, verifique as informações",
            icon: "error",
            confirmButtonColor: "red",
            heightAuto: false
          });
        }
      } else if (this.tipoSelecionado == 2) {
        try {
          this.$refs.observer.validate();

          var data3 = {
            medico: {
              crm: this.crm,
              uf_crm: this.ufs,
              especialidade_id: this.especialidadeSelect,
              pessoa_attributes: {
                nome: this.nome,
                data_nascimento: this.dataNascimento,
                rg: this.rg,
                cpf: this.cpf,
                sexo_id: this.sexo
              },
              usuario_attributes: {
                email: this.email,
                password: this.senha,
                password_confirmation: this.senhaConfirm,
                username: this.nomeUsuario,
                setor_id: this.setor
              }
            }
          };

          const response = await ambulatorio.registerUserMedicos(data3);

          console.log(response);

          Swal.fire({
            title: "",
            text: "Cadastro de médico realizado com sucesso",
            icon: "success",
            confirmButtonColor: "green",
            heightAuto: false
          });

          setTimeout(() => {
            this.$router.push({ name: "painelUsuariosContas" });
          }, 1000);
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: "",
            text:
              "Erro ao realizar cadastro de médico, verifique as informações",
            icon: "error",
            confirmButtonColor: "red",
            heightAuto: false
          });
        }
      } else if (this.tipoSelecionado == 3) {
        try {
          this.$refs.observer.validate();

          var data4 = {
            enfermeiro: {
              coren: this.coren,
              uf_coren: this.ufcoren,
              pessoa_attributes: {
                nome: this.nome,
                data_nascimento: this.dataNascimento,
                rg: this.rg,
                cpf: this.cpf,
                sexo_id: this.sexo
              },
              usuario_attributes: {
                email: this.email,
                password: this.senha,
                username: this.nomeUsuario,
                setor_id: this.setor
              }
            }
          };

          const response = await ambulatorio.registerUserEnfermeiros(data4);

          console.log(response);

          Swal.fire({
            title: "",
            text: "Cadastro de enfermeiro realizado com sucesso",
            icon: "success",
            confirmButtonColor: "green",
            heightAuto: false
          });

          setTimeout(() => {
            this.$router.push({ name: "painelUsuariosContas" });
          }, 1000);
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: "",
            text:
              "Erro ao realizar cadastro de enfermeiro, verifique as informações",
            icon: "error",
            confirmButtonColor: "red",
            heightAuto: false
          });
        }
      } else if (this.tipoSelecionado == 4) {
        try {
          this.$refs.observer.validate();

          var data5 = {
            aluno: {
              matricula: this.matricula,
              pessoa_attributes: {
                nome: this.nome,
                data_nascimento: this.dataNascimento,
                rg: this.rg,
                cpf: this.cpf,
                sexo_id: this.sexo
              },
              usuario_attributes: {
                email: this.email,
                password: this.senha,
                password_confirmation: this.senhaConfirm,
                username: this.nomeUsuario,
                setor_id: this.setor
              }
            }
          };

          const response = await ambulatorio.registerUserAluno(data5);

          console.log(response);

          Swal.fire({
            title: "",
            text: "Cadastro de aluno realizado com sucesso",
            icon: "success",
            confirmButtonColor: "green",
            heightAuto: false
          });

          setTimeout(() => {
            this.$router.push({ name: "painelUsuariosContas" });
          }, 1000);
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: "",
            text:
              "Erro ao realizar cadastro de aluno, verifique as informações",
            icon: "error",
            confirmButtonColor: "red",
            heightAuto: false
          });
        }
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Cadastrar usuário" }]);
  }
};
</script>

<style>
.theme--light.v-messages {
  color: red !important ;
}
</style>
